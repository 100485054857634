<template>
  <footer class="w-full border-t border-gray-200 bg-primary">
    <div
        class="container flex flex-wrap items-center justify-center px-4 py-8 mx-auto  lg:justify-between"
    >
      <div class="flex flex-wrap justify-center">
        <ul class="flex items-center space-x-4">
          <li><a href="/">Home</a></li>
          <li><a href="/about">Over ons</a></li>
          <li><a href="/ageselection">Bestellen</a></li>
          <li><a href="/contact">Contacteer ons</a></li>
        </ul>
      </div>
      <div class="flex justify-center mt-4 lg:mt-0">
        <a :href="facebookLink">
          <span class="iconify h-8 w-8" data-icon="ant-design:facebook-outlined" data-inline="false"/>
        </a>
        <a :href="instagramLink">
          <span class="iconify h-8 w-8" data-icon="ant-design:instagram-outlined" data-inline="false"/>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import {FACEBOOK, INSTAGRAM} from "../../../constants/ExteriorLinks";

export default {
  name: "foot",
  data() {
    return {
      facebookLink: FACEBOOK,
      instagramLink: INSTAGRAM,
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div :class="'flex bg-white rounded-full text-md ' + getHeight()">
    <div
        class="flex justify-center items-center bg-tertiary hover:bg-tertiary-light rounded-tl-full rounded-bl-full text-gray-700 w-12 cursor-pointer"
        @click="onDecrement"
    >
      <span class="iconify h-3 w-3" data-icon="akar-icons:minus" data-inline="false"></span>
    </div>
    <div class="flex justify-center items-center border-tertiary border-t-4 border-b-4 text-gray-700 w-12 select-none">
      <p>{{ amount }}</p>
    </div>
    <div
        class="flex justify-center items-center bg-tertiary hover:bg-tertiary-light rounded-tr-full rounded-br-full text-gray-700 w-12 cursor-pointer"
        @click="onIncrement"
    >
      <span class="iconify h-3 w-3" data-icon="akar-icons:plus" data-inline="false"></span>
    </div>
  </div>
</template>

<script>
import {SMALL} from "../../constants/Variants";

export default {
  name: "AmountSelector",
  props: {
    amount: Number,
    onIncrement: Function,
    onDecrement: Function,
    variant: String,
  },
  methods: {
    getHeight() {
      if (this.variant === SMALL) {
        return 'h-7 w-16';
      } else {
        return 'h-10 w-24'
      }
    },
  }
}
</script>

<style scoped>

</style>

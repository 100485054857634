<template>
  <div class="mb-20">
    <div class="flex flex-col px-2 md:px-10 md:flex-row w-full xl:w-3/4 mx-auto">
      <div class="flex flex-col md:w-2/3 sm:w-full">
        <div v-for="category in Object.keys(sortedCart)">

          <div class="w-full text-center mb-8" v-if="sortedCart[category].length">
            <h1 class="font-poppins text-xl">{{ parseCategoryToAges(category) }}</h1>
          </div>

          <div class="w-full mx-auto text-sm xl:text-md" v-for="cartItem in sortedCart[category]">
            <div
                class="flex items-center bg-white rounded-xl shadow-sm w-full px-3 md:px-10 py-4 md:py-6 mb-5 space-x-3 font-poppins text-gray-700">
              <span class="w-4/6">{{ cartItem.product.name }}</span>
              <div class="flex flex-row-reverse items-center justify-between w-3/6 xl:w-2/6">
                <div @click="() => deleteCartItem(cartItem)">
                <span
                    class="iconify h-5 w-5 md:h-6 md:w-6 text-red-600 cursor-pointer hover:text-red-500"
                    data-icon="mdi:delete"
                ></span>
                </div>
                <span class="text-center">
                   € {{ getPriceOfCartItem(cartItem) }}
                </span>
                <span class="text-center">
                      <amount-selector
                          :amount="cartItem.amount"
                          :on-increment="() => onIncrement(cartItem)"
                          :on-decrement="() => onDecrement(cartItem)"
                          :variant="smallVariant">
                    </amount-selector>
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-1/3 bg-white px-5 py-3 h-1/3 ml-5 rounded-xl shadow-sm hidden md:block">
        <div class="flex flex-col text-sm">
          <span class="text-xl mb-2">Levering</span>
          <div class="space-y-1">
            <cart-delivery-detail content="Afhalen op twee locaties"></cart-delivery-detail>
            <cart-delivery-detail content="Thuis geleverd in een straal van 10 km voor € 5"></cart-delivery-detail>
          </div>
        </div>

        <div class="mt-3">
          <divider></divider>
        </div>

        <div class="flex flex-col mt-3">
          <div class="flex justify-between text-sm lg:text-base">
            <span class="font-bold">Aantal: </span>
            <span>{{ getTotalCount(cart) }} potjes/boxen</span>
          </div>

          <div class="flex justify-between mt-0.5 text-sm lg:text-base">
            <span class="font-bold">Totaal: </span>
            <span>€ {{ getTotalPrice(cart) }}</span>
          </div>

          <span class="mt-5 mb-1">
          <cta-button :on-click="onOrderClick" content="Bestellen" :variant="secondary"></cta-button>
        </span>
        </div>
      </div>
    </div>
    <div
        class="flex items-center px-3 fixed bottom-0 h-20 w-full md:hidden bg-white border-t-2 border-gray-300 rounded-t-lg">
      <div class="flex flex-col w-1/2 space-y-1">
        <div class="flex text-center">
          <span class="font-bold mr-2">Totaal: </span>
          <span>€ {{ getTotalPrice(cart) }}</span>
        </div>
        <div class="flex text-center">
          <span class="font-bold mr-2">Aantal: </span>
          <span>{{ getTotalCount(cart) }} potjes</span>
        </div>
      </div>
      <div class="w-1/2 text-center">
        <cta-button :on-click="onOrderClick" content="Bestellen" :variant="secondary"></cta-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {parseToCommaSeparated} from "../../../helpers/StringHelper";
import {SECONDARY, SMALL} from "../../../constants/Variants";
import {ShoppingCartService} from "../../../services/ShoppingCartService";
import {sortCartByCategory} from "../../../helpers/CartHelper";
import {parseCategoryToAges} from "../../../helpers/CategoryHelper";

export default {
  name: "CartList",
  data() {
    return {
      secondary: SECONDARY,
      smallVariant: SMALL,
      shoppingCartService: new ShoppingCartService(),
      parseCategoryToAges: (category) => parseCategoryToAges(category),
    }
  },
  methods: {
    onIncrement(cartItem) {
      this.shoppingCartService.updateProductInCart(cartItem.product, ++cartItem.amount);
    },
    onDecrement(cartItem) {
      this.shoppingCartService.updateProductInCart(cartItem.product, --cartItem.amount);
    },
    deleteCartItem(cartItem) {
      this.shoppingCartService.updateProductInCart(cartItem.product, 0);
    },
    onOrderClick() {
      window.location.href = '/cart/checkout';
    },
    getPriceOfCartItem(cartItem) {
      return parseToCommaSeparated(cartItem.product.price * cartItem.amount, 2);
    },
    getTotalPrice(cartItems) {
      let price = 0;

      cartItems.forEach(cartItem => {
        price += cartItem.product.price * cartItem.amount;
      })

      return parseToCommaSeparated(price, 2);
    },
    getTotalCount(cartItems) {
      let total = 0;

      cartItems.forEach(cartItem => {
        total += cartItem.amount * cartItem.product.contentAmount;
      });

      return total;
    },
  },
  computed: {
    ...mapState({
      cart: state => state.cart,
      sortedCart: state => sortCartByCategory(state.cart),
    }),
  }
}
</script>

<style scoped>

</style>

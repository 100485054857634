export const LIGHT = 'LIGHT';
export const DARK = 'DARK';

export const PRIMARY = 'PRIMARY';
export const SECONDARY = 'SECONDARY';
export const OUTLINED = 'OUTLINED';
export const WARNING = 'WARNING';
export const DANGER = 'DANGER';

export const BIG = 'BIG';
export const SMALL = 'SMALL';

export const ADDRESS_USER = 'ADDRESS_USER';
export const ADDRESS_COLLECTION = 'ADDRESS_COLLECTION';

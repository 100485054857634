<template>
  <modal
      :is-active="isActive"
      :on-close="onClose"
      :show-cancel-button="true">
    <div class="flex space-x-3 items-center h-1/2">
      <h1 class="text-xl font-semibold">Gebruikerinformatie</h1>
    </div>

    <div v-if="user" class="flex flex-col mt-5 sm:text-base text-sm space-y-3 ml-3">
      <div class="flex flex-col">
        <span class="font-semibold">Naam: </span>
        <span class="">{{user.getFullName()}}</span>
      </div>
      <div class="flex flex-col">
        <span class="font-semibold">E-mailadres: </span>
        <span class="">{{user.email}}</span>
      </div>
      <div class="flex flex-col">
        <span class="font-semibold">Telefoonnummer: </span>
        <span class="">{{user.telephoneNumber}}</span>
      </div>
      <div class="flex flex-col">
        <span class="font-semibold">Adres: </span>
        <div class="flex flex-col">
          <span>{{`${user.street} ${user.houseNumber}`}}</span>
          <span>{{`${user.postalCode} ${user.city}`}}</span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {User} from "../../models/User";

export default {
  name: "UserInfoModal",
  props: {
    isActive: Boolean,
    onClose: Function,
    user: User,
  }
}
</script>

<style scoped>

</style>

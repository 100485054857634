<template>
  <div class="flex flex-col font-poppins w-full h-full text-white shadow-xl sm:rounded-b-2xl">
    <div :class="`w-full h-1/3 sm:rounded-t-2xl px-5 py-5 ${getColor()}`">
      <div class="flex flex-col">
        <h1 class="font-semibold text-xl mb-5">{{ this.title }}</h1>
        <p>{{ `${this.street} ${this.number}` }}</p>
        <p>{{ `${this.postalCode} ${this.city}` }}</p>
        <p>{{ `Tel: ${this.telephone}` }}</p>
        <button type="button" class="flex flex-row items-center bg-white shadow-lg rounded-lg xl:w-1/3 lg:w-1/2 sm:w-1/3 xs:w-1/2 w-full xl:p-1 p-3 h-10 mt-5 focus:outline-none hover:bg-gray-50"
                @click="goToMaps()">
          <span class="iconify text-3xl text-blue-600 w-1/6" data-icon="uil:directions" data-inline="false"></span>
          <span class="text-gray-700 font-semibold w-5/6">Routebeschrijving</span>
        </button>
      </div>
    </div>
    <div class="w-full h-2/3 bg-cover bg-center sm:rounded-b-2xl cursor-pointer" :style="getBackgroundStyle()"
         @click="goToMaps()">
    </div>
  </div>
</template>

<script>
import {PRIMARY} from "../../../constants/Variants";

export default {
  name: "ContactInfo",
  props: {
    title: String,
    street: String,
    number: String,
    city: String,
    postalCode: String,
    telephone: String,
    mapImage: String,
    mapLink: String,
    variant: String,
  },
  data() {
    return {
      getColor() {
        if (this.variant === PRIMARY) {
          return 'bg-primary-dark';
        } else {
          return 'bg-secondary';
        }
      },
      getBackgroundStyle() {
        return `height:20rem; background-image: url(${this.mapImage});`
      },
      goToMaps() {
        window.location.href = this.mapLink;
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <address-modal
        :is-active="addressModalIsActive"
        :on-close="closeAddressModal"
        :user="user">
    </address-modal>

    <address-info-item
        :row-a-content="this.user && this.user.street ? `${this.user.street} ${this.user.houseNumber} ${this.user.addition}` : ''"
        :row-b-content="this.user ? `${this.user.postalCode} - ${this.user.city}` : ''"
        empty-content="Adres toevoegen"
        :on-click="openAddressModal"
    ></address-info-item>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DeliveryAddress",
  data() {
    return {
      addressModalIsActive: false,
    }
  },
  methods: {
    openAddressModal() {
      this.addressModalIsActive = true;
    },
    closeAddressModal() {
      this.addressModalIsActive = false;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
}
</script>

<style scoped>

</style>

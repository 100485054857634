import store from "../store"
import {BASE_URL} from "../constants/Urls";
import axios from "axios";
import {captureMessage} from "@sentry/vue";
import {CartItem} from "../models/CartItem";

export class ShoppingCartService {

    async updateProductInCart({id}, amount) {
        const data = {
            amount: amount,
        };

        return await axios.post(BASE_URL + 'cart/' + id, data)
            .then(response => {
                if (response.data.success) {
                    let cartItem;

                    if (response.data.data) {
                        cartItem = CartItem.parse(response.data.data);
                        store.commit('UPDATE_CART', cartItem);
                    } else {
                        store.commit('DELETE_FROM_CART', id);
                    }

                    return cartItem;
                } else {
                    throw Error(response.data.message);
                }
            });
    }

    async getShoppingCart() {
        let cart;

        if (store.state.user) {
            axios.get(BASE_URL + 'cart')
                .then(response => {
                    if (response.data.success) {
                        const cartItems = response.data.data.map(cartItemData => {
                            return CartItem.parse(cartItemData);
                        });

                        store.commit('SET_CART', cartItems);

                        return cartItems;
                    } else {
                        throw Error(response.data.message);
                    }
                })
                .catch(error => {
                    captureMessage(error);
                })
        } else {
            cart = new Map(store.state.cart);
        }

        return cart;
    }
}

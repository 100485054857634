import { render, staticRenderFns } from "./DeliverySelector.vue?vue&type=template&id=103aeb1c&scoped=true&"
import script from "./DeliverySelector.vue?vue&type=script&lang=js&"
export * from "./DeliverySelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "103aeb1c",
  null
  
)

export default component.exports
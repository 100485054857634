export function parseCategoryToAges(category) {
    let ageText = '';

    category = parseInt(category);

    switch (category) {
        case 1:
        case 'category1':
            ageText = '4 - 6 maanden';
            break;
        case 2:
        case 'category2':
            ageText = '6 - 12 maanden';
            break;
        case 3:
        case 'category3':
            ageText = '8 - 12 maanden';
            break;
        case 4:
        case 'category4':
            ageText = '12+ maanden';
            break;
    }

    return ageText;
}

export function capitalize(text) {
    const firstLetter = text.substr(0, 1).toUpperCase();

    return firstLetter + text.substr(1, text.length - 1);
}

export function parseToCommaSeparated(number, fixedAmount = 0) {
    if (!number) return 0;

    if (fixedAmount) {
        if (typeof number === 'string') number = parseFloat(number);

        number = number.toFixed(fixedAmount);
    }

    number = number.toString();

    return number.replace('.', ',');
}

export function parseNumber(number) {
    number = number + '';

    const parsedNumber = parseFloat(number.replace(',', '.'));

    return isNaN(parsedNumber) ? 0 : parsedNumber;
}

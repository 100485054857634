import axios from "axios";
import {BASE_URL} from "../constants/Urls";
import store from "../store";
import {User} from "../models/User";

export class UserService {
    async updateAddress(data) {
        return await axios.post(BASE_URL + 'changeAddress', data)
            .then(response => {
                if (response.data.success) {
                    let user = new User();
                    user.parse(response.data.data);

                    store.commit('SET_USER', user);
                } else {
                    throw Error(response.data.message);
                }
            });
    }

    async updatePassword(data) {
        return await axios.post(BASE_URL + 'password', data)
            .then(response => {
                if (!response.data.success) {
                    throw Error(response.data.message);
                }
            });
    }
}

<template>
  <modal :is-active="error.active" :on-close="onClose" show-cancel-button>
    <div class="flex space-x-3 items-center h-1/2">
      <span :class="'iconify h-8 w-8 ' + getIconColor()" data-icon="mdi:warning"></span>
      <h1 class="text-xl font-semibold">Opgelet!</h1>
    </div>

    <div class="mt-5 w-full">
      <p>{{ error.message }}</p>
      <p class="mt-5 text-sm text-gray-700">Een bericht werd verzonden naar de ontwikkelaar. Mocht je toch nog vragen of opmerkingen hebben, dan kan je ons contacteren.</p>
    </div>
  </modal>
</template>

<script>
import store from "../../store";
import {WARNING} from "../../constants/Variants";

export default {
  name: "ErrorMessageModal",
  methods: {
    onClose() {
      store.commit('SET_ERROR', null);
    },
    getIconColor() {
      let color;

      if (this.error.variant === WARNING) {
        color = 'text-yellow-500';
      } else {
        color = 'text-red-600';
      }

      return color;
    },
  },
  computed: {
    error() {
      return this.$store.state.error ?? null;
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div v-if="isActive" class="fixed z-10 inset-0 overflow-y-auto"
       aria-labelledby="modal-title"
       role="dialog" aria-modal="true" @click="onClose">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
          @click.stop
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full font-poppins">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <slot></slot>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button"
                  v-if="submitButton"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-secondary-hover sm:ml-3 sm:w-auto sm:text-sm"
                  @click="submitButton.onClick"
          >
            {{ submitButton.content }}
          </button>
          <button type="button"
                  v-if="showCancelButton"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="onClose"
          >
            Annuleren
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isActive: Boolean,
    onClose: Function,
    submitButton: Object,
    showCancelButton: Boolean
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="flex space-x-2">
    <div class=" w-1/12 lg:2/12">
      <span class="iconify text-green-600 font-bold h-6 w-6" data-icon="mdi-check-circle"></span>
    </div>
    <div class="w-11/12 lg:w-10/12">
      <span class="text-md">{{ content }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartDeliveryDetail",
  props: {
    content: String,
  }
}
</script>

<style scoped>

</style>

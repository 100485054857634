<template>
  <carousel :per-page="1" autoplay>
    <slide v-for="(image, index) in images" :key="index">
      <div class="w-full bg-contain bg-center bg-no-repeat" :style="getImageStyle(image)"></div>
    </slide>
  </carousel>
</template>

<script>
export default {
  name: "ImageCarousel",
  props: {
    images: Array,
  },
  data() {
    return {
      getImageStyle(image) {
        return `height: 20rem; background-image: url(${image});`
      },
    }
  }
}
</script>

<style scoped>

</style>
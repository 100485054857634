<template>
  <!--
      Mobile menu, toggle classes based on menu state.

      Menu open: "block", Menu closed: "hidden"
    -->
  <div :class="getStyling()">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <horizontal-nav-bar-item :is-active="currentUrl === '/'" name="Home" link="/" :is-mobile="true"/>
      <horizontal-nav-bar-item :is-active="currentUrl === 'about'" name="Over ons" link="/about" :is-mobile="true"/>
      <horizontal-nav-bar-item :is-active="currentUrl === 'ageselection'" name="Bestellen" link="/ageselection" :is-mobile="true"/>
      <horizontal-nav-bar-item :is-active="currentUrl === 'contact'" name="Meer informatie" link="/contact" :is-mobile="true"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileNavItemList",
  props: {
    isCollapsed: Boolean,
    currentUrl: String,
  },
  data() {
    return {
      getStyling() {
        const defaultStyling = 'sm:hidden';
        let styling;

        if (this.isCollapsed) {
          styling = 'hidden';
        } else {
          styling = 'block';
        }

        return defaultStyling + ' ' + styling;
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="flex flex-col">
    <div class="font-poppins space-y-5 px-5 md:px-0">
      <h1 class="font-medium text-lg md:text-2xl text-center">Jouw winkelwagentje is leeg...</h1>
      <p class="text-center text-sm md:text-md">Je kan onze producten bekijken in de webshop en daarna jouw winkelwagentje vullen</p>
      <div class="mx-auto w-1/2 md:w-3/12 lg:w-2/12">
        <cta-button content="Naar producten" :variant="secondary" :on-click="toShop"></cta-button>
      </div>
    </div>
  </div>
</template>

<script>
import {SECONDARY} from "../../../constants/Variants";

export default {
  name: "EmptyCartMessage",
  data() {
    return {
      secondary: SECONDARY,
    }
  },
  methods: {
    toShop() {
      window.location.href = '/ageselection';
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="px-3 font-poppins font-sm w-full">
    <label :class="`block text-gray-700 mb-2 ${getLabelErrorStyle()}` " :for="`input${name}`">
      {{ this.label }}
    </label>
    <input
        :class="`bg-gray-100 appearance-none block w-full rounded py-3 px-4 outline-none ${getInputErrorStyle()}`"
        :id="`input${name}`"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :type="inputType"
        :required="required"
        @blur="(event) => onChange(event.target.value)"
    />
    <p v-if="error" class="text-xs text-red-600 mt-2">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    label: String,
    name: String,
    value: [String, Number],
    error: String,
    placeholder: String,
    required: Boolean,
    type: String,
    onChange: Function,
  },
  data() {
    return {
      inputType: this.type ?? 'text',
      getLabelErrorStyle() {
          return this.error ? 'text-red-500' : '';
      },
      getInputErrorStyle() {
        if (this.error) {
          return 'border-2 border-red-500'
        }
      }
    }
  },
}
</script>

<style scoped>

</style>

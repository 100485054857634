<template>
  <div class="text-center">
    <h1 :class="`w-auto font-amatic text-3xl pb-3 ${getStyling()}`">
      <slot></slot>
    </h1>
  </div>
</template>

<script>
import {PRIMARY} from "../../constants/Variants";

export default {
  name: "UnderlinedTitle",
  props: {
    variant: String,
  },
  data() {
    return {
      getStyling() {
        if (this.variant === PRIMARY) {
          return 'border-primary';
        } else {
          return 'border-secondary';
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <tr class="cursor-pointer" @click="onOrderClick">
    <td class="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
      <div class="flex items-center">
        <div class="ml-4">
          <div class="font-medium text-gray-900">{{ order.id }}</div>
        </div>
      </div>
    </td>
    <td v-if="order.user && user.isAdmin" class="px-2 py-2">
      <span>{{ order.user.getFullName() }}</span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="text-gray-900">{{ order.date }}</div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
      <div class="text-gray-900">{{ order.getProductAmount() }}</div>
    </td>
    <td :class="getPriceStyle()">€ {{ order.total }}</td>
  </tr>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "OrdersTableItem",
  props: {
    order: Object,
    onOrderClick: Function,
  },
  methods: {
    getPriceStyle() {
      const defaultStyle = 'px-6 py-4 whitespace-nowrap text-gray-700 font-bold';

      if (this.user?.isAdmin) {
        return `${defaultStyle} hidden sm:table-cell`
      }

      return defaultStyle;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
}
</script>

<style scoped>

</style>

export class Product {
    constructor(id, name, contents, allergens, price, weight, category) {
        this.id = id;
        this.name = name;
        this.contents = contents;
        this.allergens = allergens;
        this.price = price;
        this.weight = weight;
        this.category = category;
        this.contentAmount = 1;
        this.package = false;
    }

    static parse(productData) {
        let product = new Product(
            productData.id,
            productData.name,
            productData.contents,
            productData.allergens,
            productData.price,
            productData.weight,
            productData.category,
        );

        product.contentAmount = productData.content_amount;
        product.package = productData.package;

        return product;
    }
}

<template>
  <div class="h-full w-full">
    <cart-list v-if="cart.length"></cart-list>
    <div class="mt-10">
      <empty-cart-message v-if="!cart.length"></empty-cart-message>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CartContent",
  computed: {
    ...mapState({
      cart: state => state.cart
    }),
  }
}
</script>

<style scoped>

</style>

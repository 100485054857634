import {parseCategoryToAges} from "../helpers/CategoryHelper";

export class OrderItem {
    constructor() {
        this.id = 0;
        this.name = '';
        this.amount = 0;
        this.total = 0;
    }

    parse(productData) {
        this.id = productData.id;
        this.name = productData.name;
        this.category = parseCategoryToAges(productData.category);
        this.price = productData.price;
        this.amount = productData.pivot.amount;
        this.total = productData.pivot.total;
    }
}

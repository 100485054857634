<template>
  <div class="flex rounded-xl border-2 border-gray-100 font-poppins text-sm">
    <span
        :class="'w-full rounded-l-xl px-3 py-2 text-center cursor-pointer ' + getBackgroundColor(collect)"
        @click="() => onChange(collect)"
    >
      Afhalen
      </span>
    <span
        :class="'w-full rounded-r-xl px-3 py-2 text-center pointer cursor-pointer ' + getBackgroundColor(delivery)"
        @click="() => onChange(delivery)"
    >
      Leveren
    </span>
  </div>
</template>

<script>
import {DELIVERY, COLLECT} from "../../../constants/DeliveryTypes";

export default {
  name: "RadioSelector",
  props: {
    selectedType: String,
    onChange: Function,
  },
  data() {
    return {
      delivery: DELIVERY,
      collect: COLLECT,
    }
  },
  methods: {
    getBackgroundColor(value) {
      if (this.selectedType === value) {
        return 'bg-blue-500 text-white hover:bg-blue-600';
      } else {
        return 'bg-white hover:bg-gray-100';
      }
    },
  }
}
</script>

<style scoped>

</style>

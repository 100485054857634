import {Product} from "./Product";

export class CartItem {
    constructor(id, userId, product, amount) {
        this.id = id;
        this.userId = userId;
        this.product = product
        this.amount = amount;
    }

    static parse(cartItemData) {
        const product = cartItemData.product ? Product.parse(cartItemData.product) : {};

        return new CartItem(
            cartItemData.id,
            cartItemData.user_id,
            product,
            cartItemData.amount,
        );
    }
}

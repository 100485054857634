export function sortCartByCategory(cartItems) {
    const sortedItems = {
        category1: [],
        category2: [],
        category3: [],
        category4: [],
    }

    cartItems.forEach(cartItem => {
        sortedItems[`category${cartItem.product.category}`].push(cartItem);
    });

    return sortedItems;
}

<template>
  <div class="w-full bg-cover bg-center" :style="getContainerStyle()">
    <div :class="`flex items-center justify-center h-full w-full bg-opacity-50 ${getOverlayColor()}`">
      <div class="text-center">
        <h1 :class="`font-amatic text-6xl font-semibold uppercase md:text-6xl mb-6 ${getTextColor()}`">
          {{ this.text }}
        </h1>
        <a
            v-if="buttonText && buttonLink"
            class="px-8 py-4 bg-secondary text-base text-white font-medium font-poppins rounded hover:bg-secondary-hover focus:outline-none focus:bg-secondary-hover"
            :href="this.buttonLink"
        >
          {{ this.buttonText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {LIGHT} from "../../constants/Variants";
import {SMALL} from "../../constants/Sizes";

export default {
  name: "ImageBanner",
  props: {
    image: String,
    text: String,
    buttonText: String,
    buttonLink: String,
    variant: String,
    size: String,
  },
  data() {
    return {
      getContainerStyle() {
        let height;

        if (this.size === SMALL) {
          height = 20;
        } else {
          height = 40;
        }

        return `height:${height}rem; background-image: url(${this.image});`
      },
      getOverlayColor() {
        const lightStyle = 'bg-white';
        const darkStyle = 'bg-gray-900';

        if (this.variant === LIGHT) {
          return lightStyle;
        } else {
          return darkStyle;
        }
      },
      getTextColor() {
        const darkOverlayColor = 'text-white';
        const lightOverlayColor = 'text-gray-700';

        if (this.variant === LIGHT) {
          return lightOverlayColor;
        }

        return darkOverlayColor;
      }
    }
  }
}
</script>

<style scoped>

</style>
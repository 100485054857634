<template>
  <div>
    <div class="mt-5 md:w-1/2 lg:w-1/3">
      <delivery-selector
          :selected-type="selectedDeliveryType"
          :on-change="(value) => changeDeliveryType(value)"
      ></delivery-selector>
    </div>

    <delivery-address v-if="selectedDeliveryType === delivery"></delivery-address>

    <collection-address
        v-if="selectedDeliveryType === collect"
        :selected-location="selectedLocation"
        :on-change="(location) => changeLocation(location)"
    ></collection-address>

    <div class="mt-8 w-full xs:w-1/3">
      <cta-button :variant="secondary" content="Bestellen" :on-click="createOrder"></cta-button>
    </div>
  </div>
</template>

<script>
import {COLLECT, DELIVERY} from "../../../constants/DeliveryTypes";
import {CHARLOTTE} from "../../../constants/CollectionLocations";
import {SECONDARY} from "../../../constants/Variants";
import {OrderService} from "../../../services/OrderService";

export default {
  name: "DeliveryInfo",
  data() {
    return {
      orderService: new OrderService(),
      selectedDeliveryType: COLLECT,
      delivery: DELIVERY,
      collect: COLLECT,
      selectedLocation: CHARLOTTE,
      secondary: SECONDARY,
    }
  },
  methods: {
    changeDeliveryType(value) {
      if (value !== this.selectedDeliveryType) this.selectedDeliveryType = value;
    },
    changeLocation(location) {
      this.selectedLocation = location;
    },
    createOrder() {
      this.orderService.create(this.selectedDeliveryType, this.selectedLocation)
          .then(order => {
            window.location.href = `/orders/${order.id}`;
          })
    }
  }
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./AmountSelector.vue?vue&type=template&id=41f65831&scoped=true&"
import script from "./AmountSelector.vue?vue&type=script&lang=js&"
export * from "./AmountSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f65831",
  null
  
)

export default component.exports
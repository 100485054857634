<template>
  <modal
      :submit-button="{content: 'Oké!', onClick: info.onActionClick}"
      show-cancel-button
      :is-active="info.active"
      :on-close="onClose">
    <div class="flex space-x-3 items-center h-1/2">
      <h1 class="text-xl font-semibold">{{ info.title }}</h1>
    </div>

    <div class="mt-5 w-full">
      <p>{{ info.message }}</p>
    </div>

  </modal>
</template>

<script>
import store from "../../store";
import {SECONDARY} from "../../constants/Variants";

export default {
  name: "InfoModal",
  data() {
    return {
      ctaButtonVariant: SECONDARY,
    }
  },
  methods: {
    onClose() {
      store.commit('SET_INFO', null);
    }
  },
  computed: {
    info() {
      return this.$store.state.info ?? null;
    }
  }
}
</script>

<style scoped>

</style>

import Vue from 'vue';
import store from './store/index';
import HorizontalNavBar from "./components/navigation/horizontalNavBar/HorizontalNavBar";
import HorizontalNavBarItem from "./components/navigation/horizontalNavBar/HorizontalNavBarItem";
import NavBarDropDown from "./components/navigation/NavBarDropDown";
import MobileNavItemList from "./components/navigation/horizontalNavBar/MobileNavItemList";
import ImageBanner from "./components/shared/ImageBanner";
import ImageCarousel from "./components/shared/ImageCarousel";
import VueCarousel from 'vue-carousel';
import UnderlinedTitle from "./components/shared/UnderlinedTitle";
import HeaderTitle from "./components/shared/HeaderTitle";
import ContactInfo from "./components/pages/contact/ContactInfo";
import AboutInfo from "./components/pages/about/AboutInfo";
import ShopHorizontalNavBar from "./components/navigation/horizontalNavBar/ShopHorizontalNavBar";
import TextInput from "./components/forms/TextInput";
import Divider from "./components/shared/Divider";
import AgeSelectBox from "./components/pages/ageSelect/AgeSelectBox";
import ProductCard from "./components/pages/ProductCard";
import AddToCartModal from "./components/modals/AddToCartModal";
import AmountSelector from "./components/shared/AmountSelector";
import ProductOverview from "./components/pages/shop/ProductOverview";
import CartIcon from "./components/navigation/horizontalNavBar/CartIcon";
import AccountIcon from "./components/navigation/horizontalNavBar/AccountIcon";
import {CartItem} from "./models/CartItem";
import Button from "./components/shared/Button";
import ErrorMessageModal from "./components/modals/ErrorMessageModal";
import Modal from "./components/modals/Modal";
import CartList from "./components/pages/cart-overview/CartList";
import CartContent from "./components/pages/cart-overview/CartContent";
import EmptyCartMessage from "./components/pages/cart-overview/EmptyCartMessage";
import CartDeliveryDetail from "./components/pages/cart-overview/CartDeliveryDetail";
import DeliverySelector from "./components/pages/checkout/DeliverySelector";
import DeliveryInfo from "./components/pages/checkout/DeliveryInfo";
import DeliveryAddress from "./components/pages/checkout/DeliveryAddress";
import AddressModal from "./components/modals/AddressModal";
import {User} from "./models/User";
import AddressInfoItem from "./components/pages/checkout/AddressInfoItem";
import CollectionAddress from "./components/pages/checkout/CollectionAddress";
import OrdersTable from "./components/pages/orders/OrdersTable";
import OrdersTableHeader from "./components/pages/orders/OrdersTableHeader";
import OrdersTableItem from "./components/pages/orders/OrdersTableItem";
import OrderContent from "./components/pages/orders/OrderContent";
import Pagination from "./components/shared/Pagination";
import UserInfoModal from "./components/modals/UserInfoModal";
import InfoModal from "./components/modals/InfoModal";
import Foot from "./components/shared/partials/Foot";
import UserInfo from "./components/pages/profile/UserInfo";
import PasswordModal from "./components/modals/PasswordModal";

// Forms
Vue.component('text-input', TextInput);

// Navigation
Vue.component('horizontal-nav-bar', HorizontalNavBar);
Vue.component('shop-horizontal-nav-bar', ShopHorizontalNavBar);
Vue.component('horizontal-nav-bar-item', HorizontalNavBarItem);
Vue.component('nav-bar-drop-down', NavBarDropDown);
Vue.component('mobile-nav-item-list', MobileNavItemList);
Vue.component('cart-icon', CartIcon);
Vue.component('account-icon', AccountIcon);

// Pages
// Contact
Vue.component('contact-info', ContactInfo);

// About
Vue.component('about-info', AboutInfo);

// Age Select
Vue.component('age-select-box', AgeSelectBox);

// Shop
Vue.component('product-card', ProductCard);
Vue.component('product-overview', ProductOverview);

// Cart - overview
Vue.component('cart-content', CartContent);
Vue.component('cart-list', CartList);
Vue.component('empty-cart-message', EmptyCartMessage);
Vue.component('cart-delivery-detail', CartDeliveryDetail);

// Checkout
Vue.component('delivery-info', DeliveryInfo);
Vue.component('delivery-address', DeliveryAddress);
Vue.component('delivery-selector', DeliverySelector);
Vue.component('address-info-item', AddressInfoItem);
Vue.component('collection-address', CollectionAddress);

// Orders
Vue.component('orders-content', OrderContent);
Vue.component('orders-table', OrdersTable);
Vue.component('orders-table-header', OrdersTableHeader);
Vue.component('orders-table-item', OrdersTableItem);

// Profile
Vue.component('user-info', UserInfo);

// Modals
Vue.component('modal', Modal);
Vue.component('add-to-cart-modal', AddToCartModal);
Vue.component('error-message-modal', ErrorMessageModal);
Vue.component('info-modal', InfoModal);
Vue.component('address-modal', AddressModal);
Vue.component('user-info-modal', UserInfoModal);
Vue.component('password-modal', PasswordModal);

// Shared
Vue.component('image-banner', ImageBanner);
Vue.component('image-carousel', ImageCarousel);
Vue.component('underlined-title', UnderlinedTitle);
Vue.component('header-title', HeaderTitle);
Vue.component('divider', Divider);
Vue.component('amount-selector', AmountSelector);
Vue.component('cta-button', Button);
Vue.component('pagination', Pagination);

// Shared - partials
Vue.component('foot', Foot);

Vue.use(VueCarousel);

const app = new Vue({
    el: '#app',
    store,
    mounted() {
        let user = new User();
        user.parse(window.user);
        this.$store.commit('SET_USER', user);

        if (user.id && window.cart) {
            this.$store.commit('SET_CART', window.cart.map(cartItemData => CartItem.parse(cartItemData)));
        }
    }
});

<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto">
      <div class="py-2 align-middle inline-block px-2 w-full text-sm">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="w-full divide-y divide-gray-200">
            <orders-table-header></orders-table-header>
            <tbody class="bg-white divide-y divide-gray-200">
            <orders-table-item
                v-for="order in orders"
                :key="order.id"
                :order="order"
                :on-order-click="() => onOrderClick(order)"></orders-table-item>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrdersTable",
  props: {
    orders: Array,
    onOrderClick: Function,
  },
}
</script>

<style scoped>

</style>

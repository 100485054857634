<template>
  <modal :is-active="isActive" :on-close="onClose" show-cancel-button
         :submit-button="{content: 'Klaar', onClick: submit}">
    <div class="flex flex-col">
      <h1 class="font-poppins font-bold text-lg">Adres wijzigen</h1>

      <div class="mt-5 space-y-5 mb-3">
        <div class="flex w-full">
          <text-input
              label="Staatnaam"
              name="street"
              placeholder="Dorpstraat"
              :value="street"
              :error="errors.street"
              :required="true"
              type="text"
              :on-change="(value) => this.street = value"
          ></text-input>
        </div>
        <div class="flex">
          <text-input
              label="Nummer"
              name="number"
              placeholder="20"
              :value="houseNumber"
              :error="errors.houseNumber"
              :required="true"
              type="number"
              :on-change="(value) => this.houseNumber = value"
          ></text-input>
          <text-input
              label="Toevoeging"
              name="addition"
              placeholder="a"
              :value="addition"
              :error="errors.addition"
              :required="true"
              type="text"
              :on-change="(value) => this.addition = value"
          ></text-input>
        </div>
        <div class="flex">
          <text-input
              label="Postcode"
              name="postalCode"
              placeholder="3800"
              :value="postalCode"
              :error="errors.postalCode"
              :required="true"
              type="number"
              :on-change="(value) => this.postalCode = value"
          ></text-input>
          <text-input
              label="Gemeente"
              name="city"
              placeholder="Sint-Truiden"
              :value="city"
              :error="errors.city"
              :required="true"
              type="text"
              :on-change="(value) => this.city = value"
          ></text-input>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {UserService} from "../../services/UserService";
import {captureEvent} from "@sentry/vue";

export default {
  name: "AddressModal",
  props: {
    isActive: Boolean,
    onClose: Function,
    user: Object,
  },
  data() {
    return {
      userService: new UserService(),
      street: user?.street,
      houseNumber: user?.houseNumber > 0 ? user.houseNumber : '',
      addition: user?.addition,
      postalCode: user?.postalCode > 0 ? user.postalCode : '',
      city: user?.city,
      errors: {
        street: '',
        houseNumber: '',
        addition: '',
        postalCode: '',
        city: '',
      },
    }
  },
  methods: {
    updateAddress() {
      const data = {
        street: this.street,
        houseNumber: this.houseNumber,
        addition: this.addition,
        postalCode: this.postalCode,
        city: this.city,
      };

      this.userService.updateAddress(data)
          .then(() => this.onClose())
          .catch(error => {
            captureEvent(error);
          })
    },
    submit() {
      this.validateTextValue(this.street, 'street', {min: 4, max: 50, required: true});
      this.validateNumber(this.houseNumber, 'houseNumber', {min: 1, max: 1000});
      this.validateTextValue(this.addition, 'addition', {min: 1, max: 20, required: false});
      this.validateNumber(this.postalCode, 'postalCode', {min: 1000, max: 9999});
      this.validateTextValue(this.city, 'city', {min: 2, max: 50, required: true});

      if (Object.values(this.errors).filter(e => e.length).length) {
        return;
      }

      this.updateAddress();
    },
    validateTextValue(value, name, {min, max, required}) {
      let error = '';

      if (!this[name]) {
        if (required) {
          error = 'Dit veld is verplicht';
        }
      } else {
        if (this[name].length < min) {
          error = `Moet minimum ${min} karakters bevatten`;
        }

        if (this[name].length > max) {
          error = `Moet minder dan ${max} karakters bevatten`;
        }
      }

      this.errors[name] = error;
    },
    validateNumber(value, name, {min, max}) {
      let error = '';

      if (this[name] === null || this[name] === undefined) {
        error = 'Dit veld is verplicht';
      }

      if (this[name] < min) {
        error = 'Waarde moet groter zijn dan ' + min;
      }

      if (this[name] > max) {
        error = 'Waarde moet kleiner zijn dan ' + max
      }

      this.errors[name] = error;
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div class="flex flex-col">
    <div class="-my-2">
      <div class="py-2 inline-block w-full px-2 sm:px-6">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="w-full divide-y divide-gray-200 table-fixed sm:table-auto">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col"
                  class="px-2 py-2 sm:px-6 sm:py-4 text-left text-xs font-medium text-gray-500 sm:uppercase tracking-wider w-2/6 sm:w-auto">
                Naam
              </th>
              <th scope="col"
                  class="px-2 py-2 sm:px-6 sm:py-4 text-left text-xs font-medium text-gray-500 sm:uppercase tracking-wider w-1/6 sm:w-auto">
                Categorie
              </th>
              <th scope="col"
                  class="px-2 py-2 sm:px-6 sm:py-4 text-left text-xs font-medium text-gray-500 sm:uppercase tracking-wider w-1/6 sm:w-auto">
                Aantal
              </th>
              <th scope="col"
                  class="px-2 py-2 sm:px-6 sm:py-4 text-left text-xs font-medium text-gray-500 sm:uppercase tracking-wider w-1/6 sm:w-auto">
                Prijs
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 text-xs sm:text-sm">
            <tr v-for="item in order.items" :key="item.id">
              <td class="px-2 py-2 sm:px-6 sm:py-4">
                <div class="flex items-center">
                  <div class="ml-4">
                    <div class="font-medium text-gray-900">{{ item.name }}</div>
                  </div>
                </div>
              </td>
              <td class="px-2 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-center sm:text-left hidden sm:table-cell">
                <div class="text-gray-900">{{ item.category }}</div>
              </td>
              <td class="px-2 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-center sm:text-left sm:hidden table-cell">
                <div class="text-gray-900">{{ shortenCategoryText(item) }}</div>
              </td>
              <td class="px-2 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-center sm:text-left text-center">
                <div class="text-gray-900">{{ item.amount }}</div>
              </td>
              <td class="px-2 py-2 sm:px-6 sm:py-4 whitespace-nowrap text-center sm:text-left text-gray-700 font-bold">€ {{
                  getTotalPrice(item)
                }}
              </td>
            </tr>
            <tr class="bg-gray-50">
              <td></td>
              <td></td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                <div class="flex flex-col">
                  <span class="font-light text-xs hidden sm:block">Totaal aantal: </span>
                  <span class="font-bold text-xs sm:text-base">{{ order.getProductAmount() }}</span>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-700">
                <div class="flex flex-col h-full items-center text-left">
                  <span class="font-light text-xs hidden sm:block">Totaal: </span>
                  <span class="font-bold text-xs sm:text-base">€ {{ order.getTotalPrice() }}</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {parseToCommaSeparated} from "../../../helpers/StringHelper";

export default {
  name: "OrderDetail",
  props: {
    order: Object,
  },
  methods: {
    getTotalPrice(item) {
      return parseToCommaSeparated(item.total);
    },
    shortenCategoryText(item) {
      return item.category.replace('maanden', '');
    }
  }
}

</script>

<style scoped>

</style>

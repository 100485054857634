<template>
  <div v-if="paginationData" class="px-4 py-3 flex items-center justify-betweensm:px-6">
    <div v-if="paginationData.last_page > 1" class="flex-1 flex justify-between items-center sm:hidden">
      <span
          class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          @click="updatePageNumber(paginationData.current_page - 1)">
        Vorige
      </span>
      <span>{{ `${paginationData.current_page}/${paginationData.last_page}` }}</span>
      <span
          class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          @click="updatePageNumber(paginationData.current_page + 1)">
        Volgende
      </span>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          {{ ' ' }}
          <span class="font-medium">{{ this.paginationData.from }}</span>
          {{ ' ' }}
          tot
          {{ ' ' }}
          <span class="font-medium">{{ this.paginationData.to }}</span>
          {{ ' ' }}
          van
          {{ ' ' }}
          <span class="font-medium">{{ this.paginationData.total }}</span>
          {{ ' ' }}
          weergegeven
        </p>
      </div>
      <div v-if="paginationData.last_page !== 1">
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <span
              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
              @click="updatePageNumber(1)">
            <span class="sr-only">Eerste</span>
            <span class="iconify h-5 w-5" data-icon="mdi:chevron-double-left"/>
          </span>
          <span
              class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
              @click="updatePageNumber(paginationData.current_page - 1)">
            <span class="sr-only">Vorige</span>
            <span class="iconify h-5 w-5" data-icon="mdi:chevron-left"/>
          </span>
          <span
              v-for="pageNumber in getRelevantPageNumbers()"
              :class="getStyle(pageNumber)"
              @click="updatePageNumber(pageNumber)">
            {{ pageNumber }}
          </span>
          <span
              class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
              @click="updatePageNumber(paginationData.current_page + 1)">
            <span class="sr-only">Volgende</span>
            <span class="iconify h-5 w-5" data-icon="mdi:chevron-right"/>
          </span>
          <span
              class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
              @click="updatePageNumber(paginationData.last_page)">
            <span class="sr-only">Laatste</span>
            <span class="iconify h-5 w-5" data-icon="mdi:chevron-double-right"/>
          </span>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    paginationData: Object,
    setPageNumber: Function,
  },
  data() {
    return {
      activeStyle: 'z-10 bg-secondary-light border-yellow-600 text-gray-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
      inactiveStyle: 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
    }
  },
  methods: {
    getStyle(page) {
      let style;

      if (this.paginationData.current_page === page) {
        style = this.activeStyle;
      } else {
        style = this.inactiveStyle;
      }

      return style;
    },
    updatePageNumber(pageNumber) {
      if (pageNumber < 1 || pageNumber > this.paginationData.last_page) return;

      this.setPageNumber(pageNumber);
    },
    getRelevantPageNumbers() {
      if (!this.paginationData) return [];

      let pageNumbers = [];
      const currentPage = this.paginationData.current_page;
      const lastPage = this.paginationData.last_page;

      if (lastPage > 5) {
        let pagesBeforeCurrent = Math.abs(currentPage - 1);
        let pagesAfterCurrent = lastPage - currentPage;

        if (pagesBeforeCurrent > 2) {
          pageNumbers.push(currentPage - 2);
          pageNumbers.push(currentPage - 1);
        } else {
          for (let i = pagesBeforeCurrent; i > 0; i--) {
            pageNumbers.push(currentPage - i);
          }
        }

        pageNumbers.push(currentPage);

        if (pagesAfterCurrent > 2) {
          pageNumbers.push(currentPage + 1);
          pageNumbers.push(currentPage + 2);
          if (pageNumbers.length < 5) {
            let difference = Math.abs(pageNumbers.length - 5);
            const remainingPages = Math.abs(lastPage - currentPage);

            if (difference > remainingPages) difference = remainingPages;
            const lastPageInList = pageNumbers[pageNumbers.length - 1];

            for (let i = 0; i < difference; i++) {
              pageNumbers.push(lastPageInList + (i + 1));
            }
          }
        } else {
          for (let i = 0; i < pagesAfterCurrent; i++) {
            pageNumbers.push(currentPage + (i + 1));
          }
        }

        if (pageNumbers.length < 4 && currentPage !== lastPage) {
          const count = 5 - pageNumbers.length;
          const lastEntry = pageNumbers[pageNumbers.length - 1];

          for (let i = 0; i < count; i++) {
            pageNumbers.push(lastEntry + (i + 1));
          }
        }

      } else {
        for (let i = 0; i < lastPage; i++) {
          pageNumbers.push(i + 1);
        }
      }

      return pageNumbers;
    }
  },
  mounted() {
    this.getRelevantPageNumbers();
  }
}
</script>

<style scoped>

</style>

<template>
  <div
      :class="'flex bg-white rounded-xl hover:shadow-sm w-full xl:w-1/2 2xl:w-1/3 text-sm xl:text-base font-poppins px-5 py-3 border-2 mt-5 cursor-pointer ' + getBorderColor()"
      @click="onClick"
  >
    <div class="flex items-center justify-center w-1/5">
      <span :class="'iconify h-6 w-6 ' + getIconColor()" :data-icon="getIcon()"></span>
    </div>
    <div v-if="rowAContent && rowAContent !== ''" class="w-4/5 text-center">
      <span :class="'block ' + getFontWeight()">{{ rowAContent }}</span>
      <span class="block" v-if="rowBContent">{{ rowBContent }}</span>
      <span class="block" v-if="rowCContent">{{ rowCContent }}</span>
    </div>
    <div v-if="!rowAContent" class="w-4/5 text-center">
      <span>{{ emptyContent }}</span>
    </div>
  </div>
</template>

<script>
import {ADDRESS_COLLECTION} from "../../../constants/Variants";

export default {
  name: "AddressInfoItem",
  props: {
    rowAContent: String,
    rowBContent: String,
    rowCContent: String,
    emptyContent: String,
    onClick: Function,
    variant: String,
    selected: Boolean,
  },
  methods: {
    getIcon() {
      if (this.variant === ADDRESS_COLLECTION) {
        return 'mdi:cube-outline';
      } else {
        return 'mdi:home';
      }
    },
    getIconColor() {
      if (this.variant === ADDRESS_COLLECTION) {
        return 'text-yellow-500';
      } else {
        return 'text-blue-600';
      }
    },
    getFontWeight() {
      if (this.variant === ADDRESS_COLLECTION) {
        return 'font-semibold';
      } else {
        return '';
      }
    },
    getBorderColor() {
      if (this.selected) {
        return 'border-blue-400'
      } else {
        return 'border-gray-100';
      }
    },
  }
}
</script>

<style scoped>

</style>

import axios from "axios";
import {BASE_URL} from "../constants/Urls";
import {Order} from "../models/Order";

export class OrderService {
    async create(deliveryType, collectionLocation) {
        const data = {
            deliveryType: deliveryType,
            collectionLocation: collectionLocation,
        };

        return axios.post(BASE_URL + 'orders', data)
            .then(response => {
                if (response.data.success) {
                    let order = new Order();
                    order.parse(response.data.data);

                    return order;
                } else {
                    throw Error(response.data.message);
                }
            });
    }

    async get(pageNumber) {
        return await axios.get(BASE_URL + 'api/orders', {params: {page: pageNumber}})
            .then(response => {
                if (response.data.success) {
                    return response.data.data;
                } else {
                    throw Error(response.data.message);
                }
            })
    }
}

<template>
  <p :class="getStyle()"></p>
</template>

<script>
export default {
  name: "Divider",
  props: {
    size: {
      type: Number,
      default: 2,
    },
  },
  methods: {
    getStyle() {
      return 'border-gray-200 rounded-full ' + 'border-b-' + this.size;
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <button type="button"
          :class="'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 font-medium text-white focus:outline-none text-sm ' + getColor()"
          @click="onClick"
  >
    {{ content }}
  </button>
</template>

<script>
import {PRIMARY, SECONDARY, DANGER} from "../../constants/Variants";

export default {
  name: "Button",
  props: {
    content: String,
    onClick: Function,
    variant: String,
  },
  methods: {
    getColor() {
      let color = '';

      switch (this.variant) {
        case PRIMARY:
          color = 'bg-primary hover:bg-primary-hover';
          break;
        case SECONDARY:
          color = 'bg-secondary hover:bg-secondary-hover';
          break;
        case DANGER:
          color = 'bg-red-600 hover:bg-red-700';
          break;
      }

      return color;
    }
  }
}
</script>

<style scoped>

</style>

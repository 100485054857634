import Vue from "vue";
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({cart: state.cart}),
});

const store = new Vuex.Store({
    state: {
        currentProduct: null,
        user: null,
        cart: [],
        error: {active: false, message: '', variant: ''},
        info: {active: false, message: '', title: '', onActionClick: null},
    },
    mutations: {
        SET_CURRENT_PRODUCT(state, product) {
            state.currentProduct = product;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_CART(state, items) {
            state.cart = items.map(item => item);
        },
        UPDATE_CART(state, cartItem) {
            const itemExists = state.cart.find(item => item.product.id === cartItem.product.id);

            if (itemExists) {
                state.cart = state.cart.map(item => {
                    if (item.product.id === cartItem.product.id) {
                        item = Object.assign({}, cartItem);
                    }

                    return item;
                })
            } else {
                state.cart = state.cart.concat([cartItem]);
            }
        },
        DELETE_FROM_CART(state, productId) {
            productId = parseInt(productId);
            state.cart = state.cart.filter(item => item.product.id !== productId);
        },
        INCREMENT(state) {
            state.count++;
        },
        SET_ERROR(state, payload) {
            // message, variant
            if (!payload) {
                payload = {
                    active: false,
                    message: '',
                    variant: '',
                }
            }

            state.error = payload;
        },
        SET_INFO(state, payload) {
            if (!payload) {
                payload = {
                    active: false,
                    title: '',
                    message: '',
                    onActionClick: null,
                }
            }

            state.info = payload;
        }
    },
    actions: {},
    plugins: [vuexLocal.plugin],
});

export default store;

<template>
  <modal :is-active="isActive" :on-close="onClose" show-cancel-button
         :submit-button="{content: 'Aanpassen', onClick: submit}">
    <div class="flex flex-col">
      <h1 class="font-poppins font-bold text-lg">Wachtwoord wijzigen</h1>

      <div class="mt-5 space-y-5">
        <div class="space-y-5 mb-3">
          <div class="flex w-full">
            <text-input
                :value="password"
                label="Huidig wachtwoord"
                name="password"
                placeholder=""
                :error="errors.password"
                :required="true"
                type="password"
                :on-change="(value) => this.password = value"
            ></text-input>
          </div>
        </div>
        <div class="space-y-5 mb-3">
          <div class="flex w-full">
            <text-input
                :value="newPassword"
                label="Nieuw wachtwoord"
                name="newPassword"
                placeholder=""
                :error="errors.newPassword"
                :required="true"
                type="password"
                :on-change="(value) => this.newPassword = value"
            ></text-input>
          </div>
        </div>
        <div class="space-y-5 mb-3">
          <div class="flex w-full">
            <text-input
                :value="checkPassword"
                label="Herhaal wachtwoord ter controle"
                name="checkPassword"
                placeholder=""
                :error="errors.checkPassword"
                :required="true"
                type="password"
                :on-change="(value) => this.checkPassword = value"
            ></text-input>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {UserService} from "../../services/UserService";

export default {
  name: "PasswordModal",
  props: {
    isActive: Boolean,
    onClose: Function,
    user: Object,
  },
  data() {
    return {
      userService: new UserService(),
      password: '',
      newPassword: '',
      checkPassword: '',
      errors: {
        password: '',
        newPassword: '',
        checkPassword: '',
      },
    }
  },
  methods: {
    submit() {
      this.validatePassword();

      if (Object.values(this.errors).filter(e => e.length).length) {
        return;
      }

      this.updatePassword();
    },
    updatePassword() {
      const data = {
        password: this.password,
        newPassword: this.newPassword,
        checkPassword: this.checkPassword,
      }

      if (this.errors.password) this.errors.password = '';

      this.userService.updatePassword(data)
          .then(() => {
            this.onClose();

            this.password = '';
            this.newPassword = '';
            this.checkPassword = '';
          })
          .catch(error => {
            if (error?.response.status === 400) {
              this.errors.password = 'Het nieuwe wachtwoord komt niet overeen met de controle';
            }

            if (error?.response.status === 401) {
              this.errors.password = 'Het wachtwoord is niet juist';
            }
          })
    },
    validatePassword() {
      const minLengthError = "Wachtwoord moet langer zijn dan 8 karakters";
      const maxLengthError = "Wachtwoord moet 20 karakters of minder zijn";

      if (this.password.length < 8) {
        this.errors.password = minLengthError;
      } else if (this.password.length > 20) {
        this.errors.password = maxLengthError;
      } else {
        this.errors.password = '';
      }

      if (this.newPassword.length < 8) {
        this.errors.newPassword = minLengthError;
      } else if (this.newPassword.length > 20) {
        this.errors.newPassword = maxLengthError;
      } else {
        this.errors.newPassword = '';
      }

      if (this.checkPassword.length < 8) {
        this.errors.checkPassword = minLengthError;
      } else if (this.checkPassword.length > 20) {
        this.errors.checkPassword = maxLengthError;
      } else {
        this.errors.checkPassword = '';
      }

      if (this.newPassword !== this.checkPassword) {
        this.errors.checkPassword = "Het nieuwe wachtwoord moet overeenkomen met de controle";
      } else {
        this.errors.checkPassword = '';
      }
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="flex flex-col sm:flex-row text-xs sm:space-x-5">
    <address-info-item
        row-a-content="Charlotte"
        row-b-content="Naamsesteenweg 377a"
        row-c-content="3800 - Sint-Truiden"
        :on-click="() => onChange(charlotte)"
        :variant="collectionVariant"
        :selected="selectedLocation === charlotte"
    ></address-info-item>
    <address-info-item
        row-a-content="Jolien"
        row-b-content="E.Moyaertslaan 19"
        row-c-content="3400 - Landen"
        :on-click="() => onChange(jolien)"
        :variant="collectionVariant"
        :selected="selectedLocation === jolien"
    ></address-info-item>
  </div>
</template>

<script>
import {CHARLOTTE, JOLIEN} from '../../../constants/CollectionLocations';
import {ADDRESS_COLLECTION} from "../../../constants/Variants";

export default {
  name: "CollectionAddress",
  props: {
    selectedLocation: String,
    onChange: Function,
  },
  data() {
    return {
      charlotte: CHARLOTTE,
      jolien: JOLIEN,
      collectionVariant: ADDRESS_COLLECTION,
    }
  }
}
</script>

<style scoped>

</style>

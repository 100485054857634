export class User {
    constructor() {
        this.id = 0;
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.street = '';
        this.houseNumber = 0;
        this.addition = '';
        this.postalCode = 0;
        this.city = '';
        this.isAdmin = false;
        this.telephoneNumber = '';
    }

    getFullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    parse(data) {
        if (!data) return;

        this.id = data.id;
        this.email = data.email;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.street = data.street;
        this.houseNumber = data.houseNumber > 0 ? data.houseNumber : '';
        this.addition = data.addition ?? '';
        this.postalCode = data.postalCode > 0 ? data.postalCode : '';
        this.city = data.city;
        this.isAdmin = data.admin == 1;
        this.telephoneNumber = data.telephoneNumber;
    }
}

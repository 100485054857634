import { render, staticRenderFns } from "./MobileNavItemList.vue?vue&type=template&id=234c7138&scoped=true&"
import script from "./MobileNavItemList.vue?vue&type=script&lang=js&"
export * from "./MobileNavItemList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234c7138",
  null
  
)

export default component.exports
<template>
  <div class="container mx-auto relative">
    <header-title
        :title="getTitle()"
    ></header-title>

    <div class="flex justify-center w-full mt-5">
      <p class="font-poppins">{{getSubTitle()}}</p>
    </div>

    <add-to-cart-modal></add-to-cart-modal>

    <div class="mt-10">
      <underlined-title :variant="PRIMARY">Boxen</underlined-title>

      <div class="my-10 grid gap-x-10 gap-y-10 sm:grid-cols-2 xl:grid-cols-5">
        <product-card v-for="box in packages"
                      :key="box.id"
                      :product="box"
        ></product-card>
      </div>
    </div>

    <underlined-title :variant="PRIMARY">Individuele potjes</underlined-title>

    <div class="my-10 grid gap-x-10 gap-y-10 sm:grid-cols-2 xl:grid-cols-5">
      <product-card v-for="product in products"
                    :key="product.id"
                    :product="product"
      ></product-card>
    </div>
  </div>
</template>

<script>
import {parseNumber} from "../../../helpers/StringHelper";
import {PRIMARY} from "../../../constants/Variants";
import {Product} from "../../../models/Product";

export default {
  name: "ProductOverview",
  props: {
    productsData: String,
    categoryId: String,
  },
  data() {
    return {
      products: [],
      packages: [],
      PRIMARY: PRIMARY
    }
  },
  methods: {
    getTitle() {
      let title;

      switch (parseInt(this.categoryId)) {
        case 1:
          title = "Voeding voor baby's van 4-6 maanden";
          break;
        case 2:
          title = "Voeding voor baby's van 6-12 maanden";
          break;
        case 4:
          title = "Voeding voor kinderen van van 12+ maanden";
          break;
      }

      return title;
    },
    getSubTitle() {
      let title;

      switch (parseInt(this.categoryId)) {
        case 1:
          title = "De onderstaande potjes bevatten een portie van 150g";
          break;
        case 2:
          title = "De onderstaande potjes bevatten een portie van 200g";
          break;
        case 4:
          title = "De onderstaande potjes bevatten een portie van 300g";
          break;
      }

      return title;
    }
  },
  mounted() {
    const parsedProducts = JSON.parse(this.productsData)
        .map(productData => {
          let product = Product.parse(productData);
          product.price = parseNumber(product.price);

          return product;
        });

    this.products = parsedProducts.filter(product => !product.package);
    this.packages = parsedProducts.filter(product => product.package);
  }
}
</script>

<style scoped>

</style>

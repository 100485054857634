<template>
  <div class="flex flex-col bg-white rounded-md shadow-xl w-full lg:w-1/2 md:p-10 py-10">
    <div class="grid justify-items-center items-center">
      <img class="h-72 w-auto rounded-md" :src="image"
           :alt="title"/>
    </div>
    <div class="grid justify-items-center items-center">
      <div class="w-2/3 mt-5">
        <div class="text-center font-amatic mb-5">
          <h1 class="text-4xl">{{this.title}}</h1>
        </div>
        <p>
          <slot></slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutInfo",
  props: {
    title: String,
    image: String,
  },
}
</script>

<style scoped>

</style>
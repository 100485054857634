<template>
  <div>
    <address-modal
        :is-active="addressModalIsActive"
        :on-close="closeAddressModal"
        :user="user">
    </address-modal>

    <password-modal
        :is-active="passwordModalIsActive"
        :on-close="closePasswordModal"
    ></password-modal>

    <div v-if="user" class="flex flex-col mx-auto mt-10 w-full text-sm xs:text-base sm:w-2/3 bg-white shadow-md px-10 py-8 rounded-md space-y-2">
      <div>
        <div class="flex items-center space-x-2">
          <h1 class="text-xl font-semibold">Gegevens</h1>
        </div>
        <div class="flex flex-col p-4 space-y-2">
          <span>{{ user.firstName }} {{ user.lastName }}</span>
          <span>{{ user.email }}</span>
          <span>{{ user.telephoneNumber }}</span>
        </div>
      </div>
      <div>
        <div class="flex items-center space-x-2">
          <h1 class="text-xl font-semibold">Adres</h1>
          <span @click="openAddressModal">
            <em class="iconify h-6 w-6 text-blue-600 cursor-pointer" data-icon="uil:edit"></em>
          </span>
        </div>
        <div class="flex flex-col p-4 space-y-2">
          <span>{{ user.street }} {{ user.houseNumber }}</span>
          <span>{{ user.postalCode }} {{ user.city }}</span>
        </div>
      </div>
      <div>
        <h1 class="text-xl font-semibold">Wachtwoord</h1>
        <div class="xl:w-1/3 md:w-1/2 sm:w-2/3 w-full mt-5">
          <cta-button
              content="Wachtwoord aanpassen"
              :variant="dangerVariant"
              :on-click="openPasswordModal"
          ></cta-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DANGER} from "../../../constants/Variants";
import {mapState} from "vuex";

export default {
  name: "UserInfo",
  props: {
    userData: String,
  },
  data() {
    return {
      dangerVariant: DANGER,
      addressModalIsActive: false,
      passwordModalIsActive: false,
    }
  },
  methods: {
    openAddressModal() {
      this.addressModalIsActive = true;
    },
    closeAddressModal() {
      this.addressModalIsActive = false;
    },
    openPasswordModal() {
      this.passwordModalIsActive = true;
    },
    closePasswordModal() {
      this.passwordModalIsActive = false;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
}
</script>

<style scoped>

</style>

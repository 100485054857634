<template>
  <thead class="bg-gray-50">
  <tr class="text-xs">
    <th
        scope="col"
        class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
    >
      Volgnummer
    </th>
    <th v-if="user && user.isAdmin"
        scope="col"
        class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider"
    >
      Gebruiker
    </th>
    <th
        scope="col"
        class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider"
    >
      Besteldatum
    </th>
    <th
        scope="col"
        class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
    >
      Aantal
    </th>
    <th
        scope="col"
        :class="getPriceStyle()"
    >
      Bedrag
    </th>
  </tr>
  </thead>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "OrdersTableHeader",
  methods: {
    getPriceStyle() {
      const defaultStyle = 'px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider';

      if (this.user?.isAdmin) {
        return `${defaultStyle} hidden sm:table-cell`
      }

      return defaultStyle;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
}
</script>

<style scoped>

</style>

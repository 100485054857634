<template>
  <a :href="this.link" :class="getStyling()">{{ this.name }}</a>
</template>

<script>
export default {
  name: "HorizontalNavBarItem",
  props: {
    name: String,
    isActive: Boolean,
    link: String,
    isMobile: Boolean,
  },

  data() {
    return {
      getStyling() {
        const defaultStylingDesktop = 'font-amatic text-2xl md:text-3xl px-3 py-2 rounded-md';
        const defaultStylingMobile = 'font-amatic text-2xl block px-3 py-2 rounded-md text-base';

        const activeStyling = 'font-semibold text-gray-700';
        const inactiveStyling = 'text-gray-500 hover:bg-primary-hover hover:text-white';

        let styling;

        if (this.isMobile) {
          styling = defaultStylingMobile;
        } else {
          styling = defaultStylingDesktop;
        }

        if (this.isActive) {
          styling += ' ' + activeStyling;
        } else {
          styling += ' ' + inactiveStyling;
        }

        return styling;
      }
    }
  }
}
</script>

<style scoped>

</style>
